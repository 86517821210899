<template>
    <div>
        <el-tabs v-model="activeName">
            <el-tab-pane name="Infomations">
                <h5 slot="label"><i class="el-icon-date"></i> General</h5>
                <div>
                    <div>
                        <el-card>                
                            <div class="text-primary">
                                <h4>
                                    <strong><i class="el-icon-info text-primary"></i> Infomations</strong>
                                </h4>
                            </div>
                            <div class="d-flex w-100">
                                <div class="mr-4 flex-fill text-muted" style="line-height: 1.1rem;">
                                    <div><i class="el-icon-link text-warning"></i> IP address</div>
                                    <div><h4><strong>{{detailServerItem.ipAddress}}</strong></h4></div>
                                </div>
                                <div class="mr-4 flex-fill text-muted" style="line-height: 1.1rem;">
                                    <div><i class="el-icon-unlock text-warning"></i> Port</div>
                                    <div><h4><strong>{{detailServerItem.remotePort}}</strong></h4></div>
                                </div>
                                <div class="mr-4 flex-fill text-muted" style="line-height: 1.1rem;">
                                    <div><i class="el-icon-sunny text-warning"></i> Mac address</div>
                                    <div><h4><strong>{{detailServerItem.macAddress}}</strong></h4></div>
                                </div>
                                <div class="mr-4 flex-fill text-muted" style="line-height: 1.1rem;">
                                    <div><i class="el-icon-link text-warning"></i> Bmc address</div>
                                    <div><h4><strong>{{detailServerItem.bmcAddress}}</strong></h4></div>
                                </div>
                                <div class="mr-4 flex-fill text-muted" style="line-height: 1.1rem;">
                                    <div><i class="el-icon-date text-warning"></i> Last modified time</div>
                                    <div><h4><strong>{{detailServerItem.lastModifiedAt | pretyDateUtc}}</strong></h4></div>
                                </div>
                                <div class="mr-4 flex-fill text-muted" style="line-height: 1.1rem;">
                                    <div><i class="el-icon-date text-warning"></i> Last modified by</div>
                                    <div><h4><strong>{{detailServerItem.lastModifiedBy}}</strong></h4></div>
                                </div>
                            </div>
                            <div class="row w-100 justify-content-center" 
                                 v-if="detailServerItem.hardwareData || detailServerItem.hardwareUbuntuData">
                                <div class="col-2" 
                                    style="height: 11.5rem;"
                                     :key="`gpu${gaugeOpt.id}`"
                                     v-for="gaugeOpt in gaugeTempOptions">
                                    <ECharts :option="gaugeOpt.gaugeOptions" />
                                </div>
                                <div class="col-2"
                                    style="height: 11.5rem;"
                                     :key="`cpu${gaugeOpt.id}`"
                                     v-for="gaugeOpt in gaugeTempCpuOptions">
                                    <ECharts :option="gaugeOpt.gaugeOptions" />
                                </div>
                                <div class="col-2 pt-4 text-center d-flex align-items-center justify-content-center"
                                    style="height: 11.5rem;"
                                     :key="`net${indexCard}`"
                                     v-for="(networkCard, indexCard) in networkCardState">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                             style="width: 50%;"
                                             viewBox="0 0 512 461">
                                            <g>
                                                <path d="M0,416H196v30H0Z" transform="translate(0 0)" style="fill: #4d5e80" />
                                                <path d="M316,416H512v30H316Z" transform="translate(0 0)" style="fill: #3d4566" />
                                                <path d="M256,325H241v91h30V325Z" transform="translate(0 0)" style="fill: #4d5e80" />
                                                <path d="M256,325h15v91H256Z" transform="translate(0 0)"  style="fill: #3d4566" />
                                                <path d="M256,401H181v60H331V401Z" transform="translate(0 0)" 
                                                      :style="`fill: ${(networkCard.isUp ? '#40ad90' : '#FF5A75')}`"/>
                                                <path d="M256,401h75v60H256Z" transform="translate(0 0)"                                                       
                                                      :style="`fill: ${(networkCard.isUp ? '#34a774' : '#FF3153')}`" />
                                                <path d="M31,0V391H481V0Z" transform="translate(0 0)" 
                                                      :style="`fill: ${(networkCard.isUp ? '#40ad90' : '#FF5A75')}`"/>
                                                <path d="M256,0H481V391H256Z" transform="translate(0 0)"                                                       
                                                      :style="`fill: ${(networkCard.isUp ? '#34a774' : '#FF3153')}`"/>
                                                <path d="M91,61V271h30v30h30v30H361V301h30V271h30V61Z" transform="translate(0 0)" style="fill: #f3da9c" />
                                                <path d="M421,61V271H391v30H361v30H256V61Z" transform="translate(0 0)" style="fill: #f3da9c" />
                                                <path d="M151,121h30v90H151Z" transform="translate(0 0)" 
                                                      :style="`fill: ${(networkCard.isUp ? '#26b140' : '#FF3153')}`"/>
                                                <path d="M211,121h30v90H211Z" transform="translate(0 0)" 
                                                      :style="`fill: ${(networkCard.isUp ? '#26b140' : '#FF3153')}`"/>
                                                <path d="M271,121h30v90H271Z" transform="translate(0 0)" 
                                                      :style="`fill: ${(networkCard.isUp ? '#26b140' : '#FF3153')}`"/>
                                                <path d="M331,121h30v90H331Z" transform="translate(0 0)" 
                                                      :style="`fill: ${(networkCard.isUp ? '#26b140' : '#FF3153')}`" />
                                            </g>
                                        </svg>
                                        <div class="mt-2">                                        
                                            <h6 class="mb-0">
                                                <span class="mr-1">NIC{{networkCard.id}}</span> 
                                                <strong :class="`text-uppercase ${(networkCard.isUp ? 'text-success' : 'text-danger')}`">{{networkCard.operationalStatusVal}}</strong>
                                            </h6>
                                            <div><span class="text-muted">{{networkCard.ipAddress}}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <!--<div class="d-flex w-100">
                                <div class="mr-4 text-muted" style="line-height: 1.1rem;"
                                     v-if="detailServerItem.vmStatus">
                                    <div><i class="el-icon-monitor text-warning"></i> Status</div>
                                    <div>
                                        <h4 class="text-success" v-if="detailServerItem.vmStatus === 2"><strong>Running</strong></h4>
                                        <h4 class="text-danger" v-if="detailServerItem.vmStatus === 3"><strong>Shutdown</strong></h4>
                                        <h4 class="text-warning" v-if="detailServerItem.vmStatus === 10"><strong>Starting</strong></h4>
                                    </div>
                                </div>
                                <div class="mr-4 text-muted" style="line-height: 1.1rem;"
                                     v-if="detailServerItem.userUsing">
                                    <div><i class="el-icon-user text-warning"></i> Username</div>
                                    <div><h4><strong>{{detailServerItem.userUsing}}</strong></h4></div>
                                </div>
                            </div>-->
                        </el-card>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane  name="Comments">
                <h5 slot="label"><i class="el-icon-chat-line-round"></i> Comments</h5>
                <div>
                    <div>
                        <div class="w-100">
                            <div>
                                <div class="card card-prirary cardutline direct-chat direct-chat-primary"
                                     v-loading="isLoadingList">
                                    <!-- /.card-header -->
                                    <div class="card-body" style="display: block;">
                                        <div class="text-primary pl-4 mt-2">
                                            <h4 class="mb-0">
                                                <strong><i class="el-icon-chat-line-round"></i> Comments</strong>
                                                <el-button type="text" class="ml-3" @click="getServerComment">
                                                    <i class="el-icon-refresh" v-bind:class="{ 'spin-item' : isLoadingList }"></i> refresh
                                                </el-button>
                                            </h4>
                                        </div>
                                        <!-- Conversations are loaded here -->
                                        <div class="direct-chat-messages pl-4" style="height: calc(100dvh - 25rem) !important"
                                             id="objDivCommentList">
                                            <!-- Message. Default to the left -->
                                            <div :key="item.id" v-for="item in listData"
                                                 class="direct-chat-msg"
                                                 style="max-width: 50rem;">
                                                <!-- /.direct-chat-infos -->
                                                <img class="direct-chat-img"
                                                     :src="`/img/avatars/${item.createdBy}.jpg`" alt="Message User Image">
                                                <!-- /.direct-chat-img -->
                                                <div class="direct-chat-text p-2">
                                                    <div>{{item.noteContent}}</div>
                                                    <div><span class="direct-chat-timestamp">{{item.timestamp | pretyDateUtc }}</span></div>
                                                </div>
                                                <!-- /.direct-chat-text -->
                                            </div>
                                            <!-- /.direct-chat-msg -->

                                        </div>
                                        <!--/.direct-chat-messages-->
                                        <!-- Contacts are loaded here -->
                                        <div class="direct-chat-contacts">
                                            <ul class="contacts-list">
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <img class="contacts-list-img" src="/img/avatars/duyhh@irender.vn.jpg">

                                                        <div class="contacts-list-info">
                                                            <span class="contacts-list-name">
                                                                Count Dracula
                                                                <small class="contacts-list-date float-right">2/28/2015</small>
                                                            </span>
                                                            <span class="contacts-list-msg">How have you been? I was...</span>
                                                        </div>
                                                        <!-- /.contacts-list-info -->
                                                    </a>
                                                </li>
                                                <!-- End Contact Item -->
                                            </ul>
                                            <!-- /.contatcts-list -->
                                        </div>
                                        <!-- /.direct-chat-pane -->
                                    </div>
                                    <!-- /.card-body -->
                                    <div class="card-footer" style="display: block;">
                                        <div>
                                            <div class="input-group">
                                                <input type="text" name="message" placeholder="Type new comment ..."
                                                       v-model="newNoteContent" class="form-control">
                                                <span class="input-group-append">
                                                    <button type="button" class="btn btn-primary"
                                                            @click="addServerComment"
                                                            :disabled="!newNoteContent || newNoteContent === ''">
                                                        Send
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /.card-footer-->
                                </div>
                                <el-pagination @current-change="handleCurrentChange"
                                               :current-page.sync="pagination.pageIndex"
                                               :page-size="pagination.pageSize"
                                               :total="pagination.outRowsNumber"
                                               :page-sizes="[50, 100, 200, 500, 1000]"
                                               layout="total, sizes, prev, pager, next, jumper"></el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<style>
    .spin-item {
        -webkit-animation: spin 1s linear infinite;
        -moz-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>
<script>
    import ECharts from 'vue-echarts';
    import { use } from "echarts/core";
    import vmServerApi from '@/api/gpuHub/vmServer';
    import { getGaugeOptions } from "@/constant/vmMachineStatusConst";
    import {
        CanvasRenderer
    } from 'echarts/renderers'
    import { GaugeChart } from 'echarts/charts'
    import {
        GridComponent,
        TooltipComponent
    } from 'echarts/components'

    use([
        CanvasRenderer,
        GaugeChart,
        GridComponent,
        TooltipComponent
    ])

    export default {
        components: {            
            ECharts 
        },
        props: {
            detailServerItem: {
                type: Object,
                default: null
            },
        },
        watch: {
            detailServerItem() {
                this.listData = [];
                this.getServerComment();
            }
        },
        data() {
            return {
                activeName: 'Infomations',
                debounceFilterFnc: null,
                pagination: {
                    querySearch: '',
                    orderBy: 'A.Timestamp',
                    directionSort: 'ASC',
                    pageIndex: 1,
                    pageSize: 100,
                    outRowsNumber: 0,
                },
                isLoadingList: false,
                listData: [],
                newNoteContent: '',
            };
        },
        computed: {
            isShowGauge() {
                return (this.detailServerItem.hardwareData != null && this.detailServerItem.hardwareData.GpuNvidia != null);
            },
            gaugeTempOptions() {
                if (!this.detailServerItem.isUbuntuData) {
                    if (this.detailServerItem.hardwareData != null && this.detailServerItem.hardwareData.GpuNvidia != null) {
                        if (Array.isArray(this.detailServerItem.hardwareData.GpuNvidia)) {
                            return this.detailServerItem.hardwareData.GpuNvidia.map((gpuItem, indexGpu) => {
                                let tempValue = parseFloat(gpuItem.Sensors.Temperature.find(x => x.Name === 'GPU Core').Value);
                                return {
                                    id: indexGpu + 1,
                                    gaugeOptions: getGaugeOptions(`GPU ${indexGpu + 1}`, tempValue, '°C')
                                };
                            });
                        }
                        else {
                            return [this.detailServerItem.hardwareData.GpuNvidia].map((gpuItem, indexGpu) => {
                                let tempValue = parseFloat(gpuItem.Sensors.Temperature.find(x => x.Name === 'GPU Core').Value);
                                return {
                                    id: indexGpu + 1,
                                    gaugeOptions: getGaugeOptions(`GPU ${indexGpu + 1}`, tempValue, '°C')
                                };
                            });
                        }
                    }
                }
                else {
                    if (this.detailServerItem.hardwareUbuntuData != null && this.detailServerItem.hardwareUbuntuData.GpuNvidia != null) {
                        return this.detailServerItem.hardwareUbuntuData.GpuNvidia.map((gpuItem, indexGpu) => {
                            let tempValue = parseFloat(gpuItem.Sensors.Temperature);
                            return {
                                id: indexGpu + 1,
                                gaugeOptions: getGaugeOptions(`GPU ${indexGpu + 1}`, tempValue, '°C')
                            };
                        });
                    }
                }
                return [];
            },
            gaugeTempCpuOptions() {
                if (!this.detailServerItem.isUbuntuData) {
                    if (this.detailServerItem.hardwareData != null && this.detailServerItem.hardwareData.CPU != null) {
                        if (Array.isArray(this.detailServerItem.hardwareData.CPU)) {
                            return this.detailServerItem.hardwareData.CPU.map((cpuItem, indexCpu) => {
                                let tempValue = parseFloat(cpuItem.Sensors.Temperature.find(x => x.Name === 'CPU Package').Value);
                                return {
                                    id: indexCpu + 1,
                                    gaugeOptions: getGaugeOptions(`CPU ${indexCpu + 1}`, tempValue, '°C')
                                };
                            });
                        }
                        else {
                            return [this.detailServerItem.hardwareData.CPU].map((cpuItem, indexCpu) => {
                                let tempValue = parseFloat(cpuItem.Sensors.Temperature.find(x => x.Name === 'CPU Package').Value);
                                return {
                                    id: indexCpu + 1,
                                    gaugeOptions: getGaugeOptions(`CPU ${indexCpu + 1}`, tempValue, '°C')
                                };
                            });
                        }
                    }
                }
                else {
                    return this.detailServerItem.hardwareUbuntuData.CPU.map((cpuItem, indexCpu) => {
                        let tempValue = parseFloat(cpuItem.Sensors.Temperature);
                        return {
                            id: indexCpu + 1,
                            gaugeOptions: getGaugeOptions(`CPU ${indexCpu + 1}`, tempValue, '°C')
                        };
                    });
                }
                return [];
            },
            networkCardState() {
                if (!this.detailServerItem.isUbuntuData) {
                    if (this.detailServerItem.hardwareData != null && this.detailServerItem.hardwareData.NetworkCardInfo != null) {
                        let networkCardVaild = this.detailServerItem.hardwareData.NetworkCardInfo.filter(x => x.IpAddress !== null && (x.IpAddress.includes('192.168.20.')
                            || x.IpAddress.includes('192.168.25.')));
                        return networkCardVaild.map((networkCard, indexCard) => {
                            return {
                                id: indexCard + 1,
                                isUp: networkCard.IsUp,
                                ipAddress: networkCard.IpAddress,
                                operationalStatusVal: networkCard.OperationalStatusVal
                            };
                        });
                    }
                }
                else {
                    if (this.detailServerItem.hardwareUbuntuData != null && this.detailServerItem.hardwareUbuntuData.NetworkCardInfo != null) {
                        let networkCardVaild = this.detailServerItem.hardwareUbuntuData.NetworkCardInfo;
                        return networkCardVaild.map((networkCard, indexCard) => {
                            return {
                                id: indexCard + 1,
                                isUp: networkCard.IsUp,
                                ipAddress: networkCard.IpAddress,
                                operationalStatusVal: networkCard.OperationalStatusVal
                            };
                        });
                    }
                }
                return [];
            }
        },
        created(){
            this.getServerComment();
        },
        methods: {   
            // Pagination page change
            handleCurrentChange(element) {
                this.pagination.pageIndex = element;
                this.getServerComment();
            },
            getServerComment() {
                this.isLoadingList = true;
                vmServerApi.getServerComment({
                    serverId : this.detailServerItem.id,
                    pagination : this.pagination
                })
                .then((res) => {
                        try {
                            let responseData = res.data.data.data;
                            let pagingItem = res.data.data.pagingItem;
                            this.listData = responseData;
                            this.pagination.orderBy = pagingItem.orderBy;
                            this.pagination.directionSort = pagingItem.directionSort;
                            this.pagination.pageIndex = pagingItem.pageIndex;
                            this.pagination.pageSize = pagingItem.pageSize;
                            this.pagination.outRowsNumber = pagingItem.outRowsNumber;

                            var objDiv = document.getElementById("objDivCommentList");
                            objDiv.scrollTop = objDiv.scrollHeight;
                        } catch (error) {
                            console.log("getServerComment -> error", error)
                        }
                        setTimeout(() => {
                            this.isLoadingList = false;
                        }, 500);
                    })
                    .catch(error => {
                        console.log("getServerComment -> error", error);
                        setTimeout(() => {
                            this.isLoadingList = false;
                        }, 500);
                    });
            },
            addServerComment() {
                this.$confirm('This will permanently add a new comment. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    vmServerApi.addServerComment({
                        vmServerId: this.detailServerItem.id,
                        noteContent: this.newNoteContent
                    }).then((res) => {
                        try {
                            this.newNoteContent = '';
                            this.getServerComment();
                            if (res.data.result === 0) { 
                                this.$message({
                                    type: 'success',
                                    message: 'Delete completed'
                                });
                            }
                        } catch (error) {
                            console.log("addServerComment -> error", error);
                        }
                    })
                    .catch(error => {
                        this.processCatchApiRequest(error, "Request failed ...", 5);
                    });
                }).catch((error) => {
                    console.log("addServerComment -> error", error);
                });
            },
        }
    }
</script>